:root {
  --primaryColor: #2A0537;
  --primaryFont: ABeeZee;
  --secondaryFont: Abhaya Libre;
  --Title: 143px;
  --Text: 24px;
}

.Back {
  width: 1000px !important;
 
}

.Main {
  margin-top: 18vh;
}

.Banner {
  justify-content: space-between;
}

.Image {
  margin-top: 30rem !important;
}

.Seeds {
  margin-bottom: 20rem;
  height: 70vh;
}

.Back-Logo {
  position: absolute;
  top: 0;
  left: 0;
  ;
}

.Email {
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.Email:hover {
  color: white;
  background-color: var(--primaryColor);
}

a {
  text-decoration: none !important;
  color: inherit !important;
}
@media(max-width:1012) {
  .Back{
    width: 100% !important;
  }

  .Back-Logo {
    width: 100% !important;
  }
}
@media (max-width:991px) {
  .Banner {
    justify-content: center;
  }

  .Back-Logo {
    left: 0;
    width: 100%;

  }

  .Back{
    width: 100%;
  }
  .Logo {
    text-align: center;
  }

  :root {
    --Title: 60px;
    --Text: 18px;
  }

}

@media (max-width:600px) {


  .Email {
    font-size: 25px !important;
  }

  :root {
    --Title: 63px;
    --Text: 18px;
  }

  .Back-Logo {
    width: 100%;
    padding: 0 !important;
    left: 3%;
    right: 0;
    top: 20px;



  }
  

  .Main {
    margin-top: 10vh !important;
  }

  .Serv {
    margin-top: 0rem !important;
  }

  .Image {
    margin-top: 10rem !important;
  }

  .Seeds {
    margin-bottom: 2rem;
    margin-top: 0rem !important;
    height: 40vh;

  }

  .SVG {
    width: 50%;
  }

  .Back {
   width: 100% !important;
  

  }

  .Services {
    text-align: center;
  }
}